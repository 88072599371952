*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

button {
  margin: 2px;
  padding: 2px;
}